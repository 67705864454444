import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GameStatus, WORD_GAMES_BASE_URL, alphabet } from '../constants';
import { fetchWrapper } from '../helpers';
import { GameBody } from '../interfaces';
import { CurrentGameState, GamesState } from '../types';

const name = 'games'

const createInitialState = (): GamesState => ({
  index: 0,
  wordGames: [],

})

const mapGameBodyToWordGames = (words: string): CurrentGameState => {
  const letters = words.split('')
  return {
    gameStatus: GameStatus.PLAYING,
    guessWords: letters,
    maxWrongGuesses: 6,
    wrongGuesses: 0,
    lettersGuessed: [],
    lettersNotGuessed: getUniqueUpperCaseLetters(letters)
  }
}

const getUniqueUpperCaseLetters = (wordsToGuess: Array<string>): Array<string> => {
  const onlyLetters = new Set<string>()
  wordsToGuess.forEach(char => {
    const upperCaseChar = char.toUpperCase()
    if (alphabet.includes(upperCaseChar)) {
      onlyLetters.add(upperCaseChar)
    }
  })
  return Array.from(onlyLetters)
}

const createExtraActions = () => {
  const url = `${WORD_GAMES_BASE_URL}/retrieve?id=`

  const getGameById = () => {
    return createAsyncThunk<GameBody, any>(
      'games/fetchById',
      async (id: string): Promise<GameBody> => await fetchWrapper.get(`${url + id}`)
    )
  }

  return {
    getGameById: getGameById()
  }
}

const extraActions = createExtraActions()

const createExtraReducers = () => {
  const { pending, fulfilled, rejected } = extraActions.getGameById

  return (builder: ActionReducerMapBuilder<GamesState>) => {

    builder.addCase(pending, (state: GamesState) => {
      state.isLoading = true
      state.index = 0
      state.wordGames = []
    })

    builder.addCase(fulfilled, (state: GamesState, action: { payload: any }) => {
      state.disableNext = action.payload.words?.length < 2
      state.disablePrevious = true
      state.isLoading = false
      state.wordGames = action.payload.words?.map(mapGameBodyToWordGames)
    })

    builder.addCase(rejected, (state: GamesState, action: { payload: any }) => {
      state.isError = true
    })

  }
}

export const initialState = createInitialState()
const extraReducers = createExtraReducers()
const gamesSlice = createSlice({
  name,
  initialState,
  reducers: {
    guessLetter: (state: GamesState, action: { payload: string }) => {
      const { payload: letter } = action
      const wordGame = state.wordGames[state.index]
      if (wordGame.gameStatus === GameStatus.PLAYING) {
        const indexOfLetter = wordGame.lettersNotGuessed.indexOf(letter)
        if (indexOfLetter !== -1) {
          wordGame.lettersNotGuessed.splice(indexOfLetter, 1)
        } else {
          wordGame.wrongGuesses++
        }
        wordGame.lettersGuessed?.push(letter)
        
        if (wordGame.wrongGuesses === wordGame.maxWrongGuesses) {
          wordGame.gameStatus = GameStatus.LOST
        }

        if (!wordGame.lettersNotGuessed.length) {
          wordGame.gameStatus = GameStatus.WON
        }
      }
    },
    previous: (state: GamesState) => {
      if (state.index > 0) {
        state.index--
      }
      state.disablePrevious = state.index === 0
      state.disableNext = state.index === state.wordGames.length - 1
    },
    next: (state: GamesState) => {
      if (state.index < state.wordGames.length - 1) {
        state.index++
      }
      state.disablePrevious = state.index === 0
      state.disableNext = state.index === state.wordGames.length - 1
    }
  },
  extraReducers,
})

export type GameActions = typeof gamesSlice.actions
export type GamesSlice = typeof gamesSlice
export type GetGameByIdAction = typeof extraActions.getGameById
export { gamesSlice, extraActions };

