const request = (method: string) => {
  return (url: string, body?: unknown) => {
    const requestOptions: RequestInit = {
      method,
    };
    if (body) {
      requestOptions.headers = { 'Content-Type': 'application/json' }
      requestOptions.body = JSON.stringify(body);
    }
    return fetch(url, requestOptions).then(handleResponse);
  }
}

async function handleResponse(response: Response) {
  const isJson = response.headers.get('content-type')?.includes('application/json');
  const data = isJson ? await response.json() : null;

  // check for error response
  if (!response.ok) {
    const error = (data && data.message) || response.status;
    return Promise.reject(error);
  }

  return data;
}

export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE')
};