import { configureStore } from '@reduxjs/toolkit'
import { gamesSlice } from './games.slice';

const createStore = () => configureStore({
  reducer: {
    games: gamesSlice.reducer
  },
});

export default createStore
