import React from "react"
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import createStore from "./store/createStore"

export const store = createStore()
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }: { element: any }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return <Provider store={store}> {element} </Provider>
}
